import React from 'react';
import './Hero.css';

const Hero: React.FC = () => {
  return (
    <section id="hero" className="hero-section">
      <div className="container hero-container">
        <div className="hero-content">
          <p className="intro-text">Hello! I am</p>
          <p className="title">A Developer with a unique background in Show Technology</p>
          <p className="description">
            I'm a passionate developer based in Israel, specializing in creating robust web applications 
            and innovative software solutions. With additional expertise in lighting programming and show 
            control systems, I bring a unique perspective to technical challenges and user experience design.
          </p>
        </div>
        <div className="hero-image">
          <img src={require("../../assets/profile-image.jpg")} alt="Naor Bonomo" />
        </div>
      </div>
    </section>
  );
};

export default Hero; 