import React, { useState, useRef } from 'react';
import { Github, ExternalLink } from 'lucide-react';
import './Projects.css';
import { useInView } from '../../hooks/useInView';

interface Project {
  id: number;
  title: string;
  description: string;
  image: string;
  demoLink: string;
  githubLink: string;
  longDescription?: string;
  technologies?: string[];
}

interface ProjectsProps {
  selectedProject: Project | null;
  setSelectedProject: (project: Project | null) => void;
}

const Projects: React.FC<ProjectsProps> = ({ selectedProject, setSelectedProject }) => {
  const projects: Project[] = [
    {
      id: 1,
      title: "Portfolio Website",
      description: "A modern, responsive portfolio built with React and TypeScript",
      image: require("../../assets/project1-placeholder.webp"),
      demoLink: "",
      githubLink: "#",
      longDescription: "A personal portfolio website showcasing my projects and skills. Built with React, TypeScript, and modern CSS features. Deployed on AWS S3 with CloudFront CDN for optimal performance and global content delivery. Implements CI/CD pipeline using GitHub Actions for automated deployments. Features include responsive design, smooth animations, interactive elements, and SEO optimization.",
      technologies: ["React", "TypeScript", "CSS3", "AWS S3", "CloudFront", "GitHub Actions", "CI/CD"]
    },
    {
      id: 2,
      title: "Show Control System",
      description: "Custom software solution for live event management",
      image: require("../../assets/project2-placeholder.webp"),
      demoLink: "",
      githubLink: "#",
      longDescription: "A comprehensive show control system that integrates lighting, audio, and video elements. Features real-time control, AI-based programming, and multi-user support.",
      technologies: ["FastAPI", "WebSocket", "DMX", "MIDI", "ARTNET", "sACN", "NDI" ]
    },
    {
      id: 3,
      title: "Laser Sensor Network",
      description: "IoT-based laser precision height measurement system with real-time dashboard",
      image: require("../../assets/project3-placeholder.webp"),
      demoLink: "",
      githubLink: "https://github.com/naorbonomo/laser-sensor-network",
      longDescription: "An intelligent height measurement system combining laser precision with IoT connectivity. This full-stack solution uses ESP32 microcontrollers paired with laser distance sensors to provide accurate height measurements, delivered through an intuitive real-time web dashboard. Features include high-precision laser measurement, wireless sensor networking, real-time data visualization, automatic calibration, and error detection systems. The system is housed in custom-designed 3D-printed enclosures optimized for sensor alignment and environmental protection.",
      technologies: [
        "Node.js", 
        "Express.js", 
        "WebSocket", 
        "ESP32", 
        "Raspberry Pi",
        "REST API",
        "UART",
        "Fusion 360",
      ]
    }
  ];

  const ProjectItem: React.FC<{ project: Project; onClick: () => void }> = ({ project, onClick }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref);

    return (
      <div 
        ref={ref}
        className={`project-item ${isInView ? 'in-view' : ''}`}
        onClick={onClick}
      >
        <div className="project-image">
          <img src={project.image} alt={project.title} />
        </div>
        <h3>{project.title}</h3>
        <p>{project.description}</p>
      </div>
    );
  };

  // Add useEffect to manage body class
  React.useEffect(() => {
    if (selectedProject) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [selectedProject]);

  return (
    <section id="projects" className="projects-section">
      <div className="container">
        <p className="subtitle-text">Projects</p>
        <div className="projects-grid">
          {projects.map((project) => (
            <ProjectItem 
              key={project.id} 
              project={project}
              onClick={() => setSelectedProject(project)}
            />
          ))}
        </div>

        {/* Project Modal */}
        {selectedProject && (
          <div className="modal-overlay" onClick={() => setSelectedProject(null)}>
            <div 
              className="modal-content" 
              onClick={e => e.stopPropagation()}
            >
              <button className="modal-close" onClick={() => setSelectedProject(null)}>×</button>
              <div className="modal-image">
                <img src={selectedProject.image} alt={selectedProject.title} />
              </div>
              <h2>{selectedProject.title}</h2>
              <p className="modal-description">{selectedProject.longDescription}</p>
              <div className="modal-technologies">
                {selectedProject.technologies?.map(tech => (
                  <span key={tech} className="tech-tag">{tech}</span>
                ))}
              </div>
              <div className="modal-links">
                {selectedProject.githubLink && (
                  <a href={selectedProject.githubLink} className="modal-link" target="_blank" rel="noopener noreferrer">
                    <Github size={24} />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Projects; 
