import { useState, useEffect, RefObject } from 'react';

export function useInView(ref: RefObject<HTMLElement>) {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Get the element's position and dimensions
        const elementTop = entry.boundingClientRect.top;
        const elementHeight = entry.boundingClientRect.height;
        const windowHeight = window.innerHeight;

        // Calculate if the element is in the center of the viewport
        const viewportCenter = windowHeight / 2;
        const elementCenter = elementTop + elementHeight / 2;
        
        // Much wider threshold for better detection
        const threshold = windowHeight / 3; // Changed from /5 to /3
        
        // Calculate how close to center the element is
        const distanceFromCenter = Math.abs(elementCenter - viewportCenter);
        
        // More lenient center check
        const isInCenter = distanceFromCenter < threshold;

        setIsInView(entry.isIntersecting && isInCenter);
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        rootMargin: '-10% 0px -10% 0px' // Changed from -15% to -10% for wider detection area
      }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return isInView;
} 