import React from 'react';
import './Background.css';

// Define an array of pixel properties
const pixels = [
  { top: '10%', left: '0%', background: '#ff5722', duration: '6s' },
  { top: '30%', left: '20%', background: '#ffeb3b', duration: '9s' },
  { top: '50%', left: '60%', background: '#00bcd4', duration: '7s' },
  { top: '70%', left: '10%', background: '#f48fb1', duration: '8s' },
  { top: '90%', left: '80%', background: '#ff9800', duration: '3s' },
  { top: '15%', left: '25%', background: '#8e24aa', duration: '7.5s' },
  { top: '35%', left: '45%', background: '#00e676', duration: '8.2s' },
  { top: '55%', left: '65%', background: '#d500f9', duration: '6.8s' },
  { top: '75%', left: '30%', background: '#40c4ff', duration: '9.1s' },
  { top: '95%', left: '75%', background: '#ff1744', duration: '2.5s' },
  { top: '20%', left: '50%', background: '#00bfa5', duration: '7.3s' },
  { top: '40%', left: '70%', background: '#536dfe', duration: '8.7s' },
  { top: '60%', left: '40%', background: '#c51162', duration: '6.6s' },
  { top: '80%', left: '55%', background: '#651fff', duration: '9.8s' },
  { top: '100%', left: '85%', background: '#00b0ff', duration: '1.3s' },
  { top: '5%', left: '15%', background: '#ff5722', duration: '5.2s' },
  { top: '25%', left: '35%', background: '#ffeb3b', duration: '8.4s' },
  { top: '45%', left: '75%', background: '#00bcd4', duration: '6.1s' },
  { top: '65%', left: '25%', background: '#f48fb1', duration: '7.7s' },
  { top: '85%', left: '95%', background: '#ff9800', duration: '4.2s' },
  { top: '12%', left: '42%', background: '#8e24aa', duration: '8.9s' },
  { top: '32%', left: '62%', background: '#00e676', duration: '7.1s' },
  { top: '52%', left: '82%', background: '#d500f9', duration: '5.5s' },
  { top: '72%', left: '47%', background: '#40c4ff', duration: '8.3s' },
  { top: '92%', left: '92%', background: '#ff1744', duration: '3.8s' },
  { top: '17%', left: '67%', background: '#00bfa5', duration: '6.4s' },
  { top: '37%', left: '87%', background: '#536dfe', duration: '9.4s' },
  { top: '57%', left: '57%', background: '#c51162', duration: '5.9s' },
  { top: '77%', left: '72%', background: '#651fff', duration: '8.6s' },
  { top: '97%', left: '97%', background: '#00b0ff', duration: '2.8s' },
  { top: '7%', left: '32%', background: '#ff5722', duration: '7.2s' },
  { top: '27%', left: '52%', background: '#ffeb3b', duration: '6.7s' },
  { top: '47%', left: '92%', background: '#00bcd4', duration: '5.3s' },
  { top: '67%', left: '42%', background: '#f48fb1', duration: '9.5s' },
  { top: '87%', left: '12%', background: '#ff9800', duration: '4.6s' },
  { top: '13%', left: '59%', background: '#8e24aa', duration: '7.9s' },
  { top: '33%', left: '79%', background: '#00e676', duration: '6.3s' },
  { top: '53%', left: '99%', background: '#d500f9', duration: '8.1s' },
  { top: '73%', left: '64%', background: '#40c4ff', duration: '5.7s' },
  { top: '93%', left: '9%', background: '#ff1744', duration: '9.2s' },
  { top: '18%', left: '84%', background: '#00bfa5', duration: '3.4s' },
  { top: '38%', left: '4%', background: '#536dfe', duration: '7.6s' },
  { top: '58%', left: '74%', background: '#c51162', duration: '6.5s' },
  { top: '78%', left: '89%', background: '#651fff', duration: '8.8s' },
  { top: '98%', left: '14%', background: '#00b0ff', duration: '4.1s' },
];

const Background: React.FC = () => {
  return (
    <div className="background-container">
      {/* Static Background */}
      <div className="background"></div>
      
      {/* Flying Dots */}
      {pixels.map((pixel, index) => (
        <div
          key={index}
          className="pixel"
          style={{
            top: pixel.top,
            left: pixel.left,
            background: pixel.background,
            animationDuration: pixel.duration,
          }}
        ></div>
      ))}
    </div>
  );
};

export default Background; 