import React, { useRef } from 'react';
import './Skills.css';
import { useInView } from '../../hooks/useInView';

interface Skill {
  name: string;
  category: string;
}

const SkillCategory: React.FC<{ category: string, skills: Skill[] }> = ({ category, skills }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);

  return (
    <div 
      ref={ref}
      className={`skills-category ${isInView ? 'in-view' : ''}`}
    >
      <h3>{category}</h3>
      <div className="skills-list">
        {skills.map(skill => (
          <div key={skill.name} className="skill-item">
            {skill.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const Skills: React.FC = () => {
  const skills: Skill[] = [
    // Lighting Systems
    { name: 'grandMA', category: 'Lighting Systems' },
    { name: 'Madrix', category: 'Lighting Systems' },
    { name: 'Onyx', category: 'Lighting Systems' },
    { name: 'Resolume', category: 'Lighting Systems' },
    { name: 'Beyond Laser', category: 'Lighting Systems' },
    { name: 'Vectorworks', category: 'Lighting Systems' },
    { name: 'Depence', category: 'Lighting Systems' },
    
    // Frontend
    { name: 'React', category: 'Frontend' },
    { name: 'TypeScript', category: 'Frontend' },
    { name: 'HTML/CSS', category: 'Frontend' },
    { name: 'Next.js', category: 'Frontend' },
    { name: 'Tailwind CSS', category: 'Frontend' },
    { name: 'UI/UX Design', category: 'Frontend' },
    
    // Backend (combined with Tools & DevOps)
    { name: 'Node.js', category: 'Backend' },
    { name: 'Python', category: 'Backend' },
    { name: 'SQL', category: 'Backend' },
    { name: 'AWS', category: 'Backend' },
    { name: 'Git', category: 'Backend' },
    { name: 'Docker', category: 'Backend' },
    { name: 'CI/CD', category: 'Backend' },
    { name: 'Testing', category: 'Backend' },
    { name: 'RESTful APIs', category: 'Backend' },
    
    // Add new AI/ML category
    { name: 'LLMs Integration', category: 'AI & ML' },
    { name: 'Stable Diffusion', category: 'AI & ML' },
    { name: 'OpenAI API', category: 'AI & ML' },
    { name: 'Hugging Face', category: 'AI & ML' },
    { name: 'Computer Vision', category: 'AI & ML' },
    { name: 'Real-time Object Detection', category: 'AI & ML' },
    { name: 'Natural Language Processing', category: 'AI & ML' },
    { name: 'Prompt Engineering', category: 'AI & ML' },
    { name: 'AI Model Fine-tuning', category: 'AI & ML' },
  ];

  const categories = Array.from(new Set(skills.map(skill => skill.category)));

  const additionalExpertise = [
    'Project Management',
    'Event Production',
    'Technical Direction',
    'Show Programming',
    'Team Leadership',
  ];

  return (
    <section id="skills" className="skills-section">
      <div className="container">
        <p className="subtitle-text">Skills</p>
        
        <div className="skills-grid">
          {categories.map(category => (
            <SkillCategory
              key={category}
              category={category}
              skills={skills.filter(skill => skill.category === category)}
            />
          ))}
        </div>

        <div className="additional-skills">
          <p className="subtitle-text">Additional Expertise</p>
          <div className="tags-container">
            {additionalExpertise.map((skill) => (
              <span key={skill} className="skill-tag">
                {skill}
              </span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills; 