import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Hero from './components/hero/Hero';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';
import Background from './components/layout/Background';
import './App.css';

interface Project {
  id: number;
  title: string;
  description: string;
  image: string;
  demoLink: string;
  githubLink: string;
  longDescription?: string;
  technologies?: string[];
}

const ScrollToSection: React.FC = () => {
  const location = useLocation();
  
  React.useEffect(() => {
    // Wait for the page to fully render
    setTimeout(() => {
      const sectionId = location.pathname.replace('/', '') || 'hero';
      const element = document.getElementById(sectionId);
      if (element) {
        const navHeight = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });

        // Add an additional scroll for the contact section to ensure we reach the bottom
        if (sectionId === 'contact') {
          setTimeout(() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth'
            });
          }, 100);
        }
      }
    }, 100);
  }, [location]);

  return null;
};

const MainContent: React.FC = () => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <>
      <Background />
      <Navbar onNavClick={closeModal} />
      <main className="main-content">
        <Hero />
        <About />
        <Projects selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
        <Skills />
        <Contact />
      </main>
      <ScrollToSection />
    </>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/about" element={<MainContent />} />
        <Route path="/projects" element={<MainContent />} />
        <Route path="/skills" element={<MainContent />} />
        <Route path="/contact" element={<MainContent />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;