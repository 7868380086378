import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

interface NavbarProps {
  onNavClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onNavClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const scrollToSection = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    setIsMenuOpen(false);
    onNavClick();
    
    navigate(`/${id === 'hero' ? '' : id}`);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    onNavClick();
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" onClick={(e) => scrollToSection(e, 'hero')}>
            Naor Bonomo
          </Link>
        </div>

        <div 
          className={`hamburger ${isMenuOpen ? 'active' : ''}`} 
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
          <li>
            <Link to="/about" onClick={(e) => scrollToSection(e, 'about')}>About</Link>
          </li>
          <li>
            <Link to="/projects" onClick={(e) => scrollToSection(e, 'projects')}>Projects</Link>
          </li>
          <li>
            <Link to="/skills" onClick={(e) => scrollToSection(e, 'skills')}>Skills</Link>
          </li>
          <li>
            <Link to="/contact" onClick={(e) => scrollToSection(e, 'contact')}>Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar; 