import React from 'react';
import { Mail, Github, Twitter, Instagram, Linkedin } from 'lucide-react';
import './Contact.css';

const Contact: React.FC = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2>Get in Touch</h2>
        <p className="section-description">
          Have a project in mind? Let's work together to bring your ideas to life.
        </p>
        
        <div className="separator"></div>

        <div className="contact-links">
          <a href="mailto:NaorBonomo@gmail.com" className="contact-link">
            <Mail className="icon" />
          </a>

          <a
            href="https://github.com/naorbonomo"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <Github className="icon" />
          </a>

          <a
            href="https://www.linkedin.com/in/naorbonomo"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <Linkedin className="icon" />
          </a>

          <a
            href="https://twitter.com/naorbonomo"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <Twitter className="icon" />
          </a>

          <a
            href="https://instagram.com/naorbonomo"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <Instagram className="icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;