import React, { useRef } from 'react';
import { useInView } from '../../hooks/useInView';
import './About.css';

const TimelineItem: React.FC<{ title: string; content: string }> = ({ title, content }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);

  return (
    <div 
      ref={ref}
      className={`timeline-item ${isInView ? 'in-view' : ''}`}
    >
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  );
};

const About: React.FC = () => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <p className="subtitle-text">About Me</p>
        <div className="timeline">
          <TimelineItem 
            title="Background"
            content="Started my journey in software development with a strong foundation in technical systems. My unique background in show technology has given me valuable insights into real-time systems and user interface design."
          />
          <TimelineItem 
            title="Experience"
            content="Developed full-stack applications focusing on React, Node.js, and cloud technologies. Specialized in AI integration, working with various models from LLMs to image generation, creating innovative solutions that combine human creativity with artificial intelligence."
          />
          <TimelineItem 
            title="Present"
            content="Currently working as an indie full-stack developer, focusing on modern web technologies and AI integration. Combining software expertise, artificial intelligence, and show technology knowledge to create cutting-edge solutions across industries."
          />
        </div>
      </div>
    </section>
  );
};

export default About; 